import React from 'react'
import Booking from '../components/Booking/Booking'

const BookingOrder = () => {
  return (
    <>
    <Booking/>
    </>
  )
}

export default BookingOrder