import React from 'react'
import Layout from '../components/layout/Layout';
import EveeC1 from '../components/eveeC1/EveeC1';

const Eveon = () => {
  return (
    <Layout>
      <EveeC1/>
    </Layout>
  )
}

export default Eveon